// src/components/Hero/Hero.js

import React from 'react';

const Hero = () => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/resume.pdf'; // Path to your PDF in the public folder
    link.download = 'resume.pdf'; // Name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="flex flex-col items-center justify-center h-screen bg-black text-white px-6 border-b border-[#3C4142]" id="about">
      <div className="text-center">
      <h1 className="text-4xl md:text-8xl font-bold mb-6">Welcome to My Portfolio</h1>
      <p className="text-lg md:text-md mb-4 max-w-full md:max-w-[65%] mx-auto">
        Explore my journey in web development over the past 6 years, where I’ve had the privilege of delivering efficient, high-quality code for top-tier clients such as Bank of America, Merrill Lynch, Verizon Wireless, Apple, and Audi. I am passionate about crafting exceptional digital experiences and would love to collaborate on your next project. Feel free to reach out through the contact form, and let's create something great together!
      </p>
        <div className="flex flex-col items-center justify-center mt-12 space-y-4">
        <button
          onClick={handleDownload}
          className="px-6 py-3 bg-white text-[#3C4142] border border-[#3C4142] rounded-lg font-semibold hover:bg-gray-300 hover:text-[#2A2D2E] hover:border-[#2A2D2E] transition duration-300"
        >
          Download Resume
        </button>


          <br />
            OR
          
          <div className="animate-bounce">
            <a href="#projects">
            <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v10.586l3.293-3.293a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 14.586V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;