// src/components/Projects/Projects.js

import React from 'react';
import ProjectTile from './ProjectTile';

const jobHistory = [
  {
    title: "Front end developer",
    company: "Digitas",
    duration: "2018 - 2021",
    details: [
      "Developed high-impact promo landing pages for Bank of America, ensuring an engaging and user-friendly experience.",
      "Achieving ADA Compliance Playing a key role in ensuring that web products met ADA compliance, making them accessible to a broader audience and aligning with WCAG standards.",
      "Streamlined development processes by leveraging Gulp and Bower for efficient component management and local builds.",
      "Managed code deployment across multiple servers using Jenkins, ensuring seamless integration and continuous delivery.",
      "Enhanced the efficiency and maintainability of the codebase by developing strong proficiency in SCSS, Handlebars (HBS), and JavaScript.",
      "Assisted in front-end development, focusing on website updates and maintenance within a legacy AEM build.",
      "Transitioned to a new project, working with an updated AEM version and utilizing HTL/Sightly for dynamic content management.",
      "Worked closely with cross-functional teams, including backend developers, to resolve complex issues, ensuring timely updates and maintenance between multiple websites."
    ]
  },
  {
    title: "Front end developer",
    company: "Rauxa/FKA",
    duration: "2021 - present",
    details: [
      "Led development of custom landing pages, transitioning from mixed responsibilities to a full-time focus on creating unique, client-specific designs. Utilized advanced libraries such as Glide and D3.js to build ADA-compliant, user-friendly interfaces.",
      "Pioneered Marketo email campaigns by developing HTML email templates using Marketo's drag-and-drop components, ensuring consistent branding and high deliverability rates across multiple campaigns.",
      "Contributed to the development of a custom tool within the AEM system for email creation and distribution",
      "Assumed lead developer duties, including mentoring and instructing junior developers, managing ticket distribution and assignments, maintaining Git repositories, and performing thorough code reviews to uphold quality standards.",
      "Crafted high-impact ad banners for high-profile clients, utilizing GSAP, JavaScript, and Photoshop. Successfully delivered creative solutions tailored to diverse client needs and audiences across various formats and sizes.",
      "Increased client satisfaction by consistently delivering high-quality, customized landing pages on tight deadlines, leading to repeat business and stronger client relationships.",
      "Enhanced email marketing efforts by creating and documenting reusable landing page templates in Marketo, streamlining the campaign creation process and ensuring consistency across marketing initiatives.",
      "Improved cross-team collaboration by developing clear documentation and best practices for creating landing page templates in Marketo, ensuring consistent design and functionality across campaigns and making it easier for teams to replicate successful templates."
    ]
  }
  // Add more job history items here
];

const volunteerHistory = [
  {
    title: "Mentor/Tutor",
    organization: "Resilient Coders",
    duration: "2018-2020",
    details: [
      "Dedicated two years to mentoring and tutoring students at Resilient Coders, guiding them through the same front-end development curriculum that I had successfully completed, and helping them navigate challenges to achieve their goals.",
      "Provided personalized tutoring on front-end technologies, sharing not only technical skills but also valuable life lessons, fostering both professional and personal growth in students.",
      "Committed additional time beyond the scheduled community hours, attending the office every day after work to offer extra support, demonstrating a strong commitment to the success of the students.",
      "Innovatively tackled recurring challenges by developing new solutions each year, ensuring that both myself and the students were continually learning and improving."
    ]
  },
  {
    title: "Instructor",
    organization: "G Code Bootcamp",
    duration: "Winter 2020",
    details: [
      "Served as an instructor at G Code Camp, a non-profit bootcamp focused on introducing women to the tech industry, where I collaborated with three other developers to design and deliver a comprehensive front-end development curriculum.",
      "Developed and presented educational materials that covered the fundamentals of front-end development, ensuring that students gained a solid foundation in the essential skills needed for the industry.",
      "Contributed to successful outcomes for bootcamp graduates by facilitating curriculum and instruction resulting in job placement within one year of graduation.",
      "Provided support beyond standard business hours and successfully adapted to remote teaching, maintaining high levels of engagement and learning despite the challenges posed by the COVID-19 shutdown."
    ]
  }
  // Add more volunteer history items here
];

const technologies = [
  { name: "HTML5", iconUrl: "https://symbols.getvecta.com/stencil_25/37_html5.d4d8050235.svg" },
  { name: "CSS3", iconUrl: "https://symbols.getvecta.com/stencil_25/14_css3.d930bfb832.svg" },
  { name: "JavaScript", iconUrl: "https://symbols.getvecta.com/stencil_25/41_javascript.0ca26ec4ab.svg" },
  { name: "React", iconUrl: "https://symbols.getvecta.com/stencil_25/72_react.76a8d36b4b.svg" },
  { name: "Sass", iconUrl: "https://symbols.getvecta.com/stencil_25/77_sass.57898c574e.svg" },
  { name: "webpack", iconUrl: "https://symbols.getvecta.com/stencil_25/90_webpack.4b175e738b.svg" },
  { name: "gulp", iconUrl: "https://symbols.getvecta.com/stencil_25/34_gulp.9db342974c.svg" },
  { name: "aem", iconUrl: "https://cdn.worldvectorlogo.com/logos/adobe-experience-manager.svg" },
  { name: "marketo", iconUrl: "https://ewebinar.com/hubfs/marketo%20logo-1.png" },
  { name: "handlebars", iconUrl: "https://symbols.getvecta.com/stencil_25/35_handlebars.5ddac82eaa.svg" },
  { name: "github", iconUrl: "https://symbols.getvecta.com/stencil_64/30_github.f945e6f399.svg" },
  { name: "gitlab", iconUrl: "https://symbols.getvecta.com/stencil_25/30_gitlab.83a9252b1c.svg" }

  // Add more technologies here
];

const Projects = () => {
  return (
    <section className="bg-black text-white py-12 border-b border-[#3C4142]" id="projects">
      <div className="max-w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* Job History */}
        <ProjectTile title="Job History">
          {jobHistory.map((job, index) => (
            <div key={index} className="mb-6">
              <h4 className="text-lg font-bold mb-6">{job.title}</h4>
              <p className="italic mb-6">{job.company} - {job.duration}</p>
              <ul className="list-disc pl-5 mb-6">
                {job.details.map((detail, i) => (
                  <li key={i} className="mb-6">{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </ProjectTile>

        {/* Volunteer History */}
        <ProjectTile title="Volunteer History">
          {volunteerHistory.map((volunteer, index) => (
            <div key={index} className="mb-6">
              <h4 className="text-lg font-bold mb-6">{volunteer.title}</h4>
              <p className="italic mb-6">{volunteer.organization} - {volunteer.duration}</p>
              <ul className="list-disc pl-5 mb-6">
                {volunteer.details.map((detail, i) => (
                  <li key={i} className="mb-6">{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </ProjectTile>

        {/* Technologies */}
        <ProjectTile title="Technologies">
          <div className="grid grid-cols-3 gap-4 mb-6">
            {technologies.map((tech, index) => (
              <div key={index} className="flex justify-center items-center mb-6">
                <img src={tech.iconUrl} alt={tech.name} className="w-12 h-12" />
              </div>
            ))}
          </div>
        </ProjectTile>
      </div>
    </section>
  );
};

export default Projects;
